/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
// import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { TextInput } from '@abyss/web/ui/TextInput';
import Grid from '@mui/material/Grid';
import { Label } from '@abyss/web/ui/Label';

export const UpdateColumn = (props) => {
  const dispatch = useDispatch();
  const { dataToUpdate } = props;
  const [value, setValue] = useState(dataToUpdate?.NEW_MAP_COL_NM);

  const backButtonClick = useSelector((state) => {
    return state?.backButtonStatus;
  });

  const classes = {
    root: {
      flexGrow: 1,
      margin: '5px 10px 10px 80px',
    },
    lebelStyle: {
      display: 'inline-block',
      marginTop: '15px',
      fontWeight: 'bold',
      float: 'left',
      fontSize: '13px',
    },
  };

  const handleRosterColumnChange = (value) => {
    setValue(value);
  };
  return (
    <React.Fragment>
      <Modal
        title="Update column Details"
        isOpen={props.open}
        onClose={(e) => {
          return props.handleClose(e);
        }}
        data-testid="UpdateColumn"
      >
        <Modal.Section>
          <Grid style={{ marginLeft: '15px' }}>
            <Grid container spacing={1}>
              <Grid item xs={3} sm={12}>
                <Grid container item row>
                  <Label
                    size="$xl"
                    style={classes.lebelStyle}
                    data-testid="Email-label"
                  >
                    {dataToUpdate?.DEFINED_COL_NM}
                  </Label>
                  <TextInput
                    width="40%"
                    data-testid="TextInputEmail"
                    id="2"
                    value={value}
                    onChange={(e) => {
                      handleRosterColumnChange(e.target.value);
                    }}
                    css={{
                      'abyss-text-input-root': { marginLeft: '75px' },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal.Section>
        <Modal.Section>
          <Layout.Group alignLayout="right">
            <Button
              variant="outline"
              onClick={(e) => {
                return props.handleClose(e);
              }}
              data-testid="cancelButton"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                return props.handleSubmit(
                  e,
                  dataToUpdate?.DEFINED_COL_NM,
                  value
                );
              }}
              data-testid="submitButton"
            >
              Submit
            </Button>
          </Layout.Group>
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );
};
