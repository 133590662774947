import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Modal } from '@abyss/web/ui/Modal';
import { Divider } from '@abyss/web/ui/Divider';
import { hostServer } from '../../utils/constants';

const serverUrl = hostServer.dev;

export const UploadedFileHistory = (props) => {
  const { showFileHistoryFlag, setShowHistoryVal, onCloseModal } = props;

  const [FileHistoryData, setFileHistoyData] = useState([]);
  const activeAccountEmail = useSelector((state) => {
    return state.activeAccountEmail;
  });

  const fetchUploadedFile = (email) => {
    axios
      .get(`${serverUrl}/fetchUploadedFile?emailId=${email}`)
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData.length && responseData.length > 0) {
          const formatedList = [];
          responseData.forEach((item) => {
            formatedList.push({
              rosterFileName: item.ROSTERFILENAME,
              updatedDate: item?.TIMESTAMPS.substring(0, '10'),
              updatedTime: item?.TIMESTAMPS.substring(11, '19'),
            });
          });
          // console.log('formatedList', formatedList);
          setFileHistoyData(formatedList); //
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    fetchUploadedFile(activeAccountEmail);
  }, [activeAccountEmail]);

  return (
    <React.Fragment>
      <Modal
        title="Files History"
        isOpen={showFileHistoryFlag}
        onClose={onCloseModal}
        css={{
          'abyss-modal-content-title': {
            fontSize: '24px!important',
          },
        }}
        size="sm"
      >
        <Divider />
        <Modal.Section>
          {FileHistoryData && FileHistoryData?.length > 0 ? (
            <React.Fragment>
              {FileHistoryData.map((val, key) => {
                return (
                  <React.Fragment>
                    {/* {`${
                      (setTimeout(() => {
                        <p style={{ color: 'red', fontWeight: '400' }}>
                          Loading...
                        </p>;
                      }),
                      3000)
                    }`} */}

                    <p
                      className="showColumn"
                      // style={{ fontSize: 'smaller' }}
                      // onClick={handleClose}
                    >
                      {val.rosterFileName}
                    </p>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p style={{ color: 'red', fontWeight: '400' }}>
                No Data Found, Please upload a file.
              </p>
            </React.Fragment>
          )}
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );
};
