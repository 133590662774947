import React, { useState } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { MessagePopup } from '../MessagePopup';
import { startLoader, stopLoader } from '../../store/actions';
import { Loader } from '../Loader';
import { hostServer } from '../../utils/constants';
import { UpdateColumn } from './UpdateColumns';

const serverUrl = hostServer.dev;

export const UpdateNewMappedColumns = () => {
  const [x, setx] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useSelector((state) => {
  //   return state?.fetchMappedList;
  // });
  let updateColumnList = useSelector((state) => {
    return state?.fetchMappedList;
  });
  //  [
  //   {
  //     id: 1,
  //     NEW_MAP_COL_NM: 'Billing Zip\n(00000)',
  //     DEFINED_COL_NM: 'POSTALCODE',
  //   },
  //   {
  //     id: 2,
  //     NEW_MAP_COL_NM: 'Degree1',
  //     DEFINED_COL_NM: 'SEC_DEG',
  //   },
  //   {
  //     id: 3,
  //     NEW_MAP_COL_NM: 'Billing Zip\n(00000)',
  //     DEFINED_COL_NM: 'BILL_POSTALCODE',
  //   },
  //   {
  //     id: 4,
  //     NEW_MAP_COL_NM: 'DEA Number\n',
  //     DEFINED_COL_NM: 'DEA_NUMBER',
  //   },
  // ];

  updateColumnList = updateColumnList.sort((a, b) => {
    return a.DEFINED_COL_NM > b.DEFINED_COL_NM ? 1 : -1;
  });
  const loading = useSelector((state) => {
    return state.loading;
  });
  const [isLoading, setLoading] = React.useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [mappedData, setMappedData] = useState([]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Field Name',
        accessor: 'DEFINED_COL_NM',
      },
      {
        Header: 'Mapped Field Name',
        accessor: 'NEW_MAP_COL_NM',
        hiddenDefaultFilters: [
          'greater',
          'greater-equal',
          'less',
          'less-equal',
        ],
      },
    ];
  }, []);

  // const handleRefreshClick = () => {
  //   dataTablePropsPagination.reloadTableData();
  // };
  const bulkActions = [
    {
      onClick: ({ deleteRows, getSelectedRowIds, clearCache }) => {
        const ids = dataTablePropsPagination.state.selectedFlatRows;
        deleteMappedColums(ids);
        // deleteRows();
        // dataTablePropsPagination.reloadTableData();
        // console.log('-------', dataTablePropsPagination);
        // var ids1 = Object.keys(dataTablePropsPagination.state.selectedRowIds);

        // deleteRows();
        // handleRefreshClick();
        // window.location.reload();
        // dataTablePropsPagination.reloadTableData = true;
      },
      icon: <IconMaterial icon="delete" />,
      label: 'Delete Rows',
      isSeparated: true,
    },
  ];

  const deleteMappedColums = (objectData) => {
    const itemListToDelete = [];
    const itemColumnList = [];
    if (objectData && objectData.length > 0) {
      objectData.forEach((item) => {
        itemListToDelete.push(item?.original?.NEW_MAP_COL_NM);
        itemColumnList.push(item?.original?.DEFINED_COL_NM);
      });
    } else {
      itemListToDelete.push(objectData?.original?.NEW_MAP_COL_NM);
      itemColumnList.push(objectData?.original?.DEFINED_COL_NM);
    }

    setLoading(true);
    dispatch(startLoader());
    const payload = {
      itemListToDelete,
      itemColumnList,
    };

    axios
      .post(`${serverUrl}/deleteWrongMappedcolumn`, { data: payload })
      .then((response) => {
        setLoading(false);
        dispatch(stopLoader());
        setDeleteRowsFlag(true);
        fetchcolumnsData();
        // dataTablePropsPagination.reloadTableData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const individualActions = [
    {
      onClick: ({ deleteRow, row }) => {
        deleteMappedColums(row);
        deleteRow(row);
      },

      icon: <IconMaterial icon="delete" />,
      label: 'Delete Row',
      isSeparated: true,
    },
    {
      onClick: ({ modifyRow, row }) => {
        setx(row.original);
        setIsUpdate(true);
      },
      label: 'Modify Cell',
    },
  ];

  const dataTablePropsPagination = useDataTable({
    showSelection: true,
    initialData: updateColumnList,
    initialColumns: columns,
    showTopPagination: false,
    showBottomPagination: false,
    showGlobalFilter: true,
    bulkActions,
    individualActions,
    pageSizeOptions: [100000],
  });
  const fetchcolumnsData = () => {
    axios
      .get(`${serverUrl}/fetchMappedcolumn`)
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData.length && responseData.length > 0) {
          setMappedData(response.data.results);
          dataTablePropsPagination.setData(response.data.results, true);
          dispatch({
            type: 'FETCHED_SELECTED_ROW__VALUES',
            value: response.data.results,
          });
        }
      })
      .catch((error) => {});
  };

  const [deleteRowsFlag, setDeleteRowsFlag] = React.useState(false);

  const backToHomePage = () => {
    navigate('/');
  };
  const handleCloseMSG = () => {
    setDeleteRowsFlag(false);
    setUpdateFlag(false);
  };
  const handleAcknowledge = () => {
    setDeleteRowsFlag(false);
    setUpdateFlag(false);
    // window.location.reload();
  };

  const onColumnMappingSubmit = (keyVal, value) => {
    const itemToUpdate = {
      oldColumnValue: `'${x?.NEW_MAP_COL_NM}'`,
      newColumnValue: `'${value}'`,
      keyValue: `'${x.DEFINED_COL_NM}'`,
    };
    setLoading(true);
    dispatch(startLoader());
    axios
      .post(`${serverUrl}/updateWrongMappedcolumn`, { data: itemToUpdate })
      .then((response) => {
        setLoading(false);
        fetchcolumnsData();
        dispatch(stopLoader());

        setUpdateFlag(true);

        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClose = () => {
    /* istanbul ignore next */
    setIsUpdate(false);
  };

  const handleSubmit = (event, keyVal, value) => {
    // event.preventDefault();
    onColumnMappingSubmit(keyVal, value);
    setIsUpdate(false);
  };
  const messageBody = updateFlag ? 'Updated' : 'Deleted';
  return (
    <React.Fragment>
      <div>
        <Backdrop data-testid="loading-1" open={isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Loader open={loading} />
        {deleteRowsFlag && (
          <MessagePopup
            open={deleteRowsFlag}
            handleClose={handleCloseMSG}
            handleAcknowledge={handleAcknowledge}
            message={`Column ${messageBody} Successfully`}
            errorName=""
            successCase
          />
        )}
        {updateFlag && (
          <MessagePopup
            open={updateFlag}
            handleClose={handleCloseMSG}
            handleAcknowledge={handleAcknowledge}
            message={`Column ${messageBody} Successfully`}
            errorName=""
            successCase
          />
        )}
        {/* {!isLoading && (
          <Button
            variant="solid"
            onClick={() => {
              backToHomePage();
            }}
            style={{ marginTop: '4px' }}
          >
            Back to Home
          </Button>
        )} */}
        <DataTable
          hideTitleHeader
          title="Update mapped column"
          tableState={dataTablePropsPagination}
        />
        {isUpdate && (
          <UpdateColumn
            open={isUpdate}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            dataToUpdate={x}
          />
        )}
        {/* <pre style={{ maxHeight: '200px', overflow: 'auto' }}>

        </pre> */}
      </div>
    </React.Fragment>
  );
};
