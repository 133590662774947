/* eslint-disable import/no-default-export */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from 'react-redux';

export const Dropdown = ({
  classes,
  id,
  allValues,
  value,
  orignalValue,
  mappedData,
}) => {
  // console.log('allValues====', allValues);
  // console.log('value====', value);
  // console.log('orignalValue====', orignalValue);
  // console.log('mappedData====', mappedData);

  const dropDownValues = allValues;
  // console.log('dropDownValues====', dropDownValues);
  const dispatch = useDispatch();

  /* istanbul ignore next */
  const handleChange = (event) => {
    if (event && event !== undefined) {
      dispatch({ type: 'FILE_BACK_BUTTON_STATUS', value: false });
      dispatch({
        type: 'SET_OBJECT_VALUES',
        value: { orignal: orignalValue, tobeUpdate: event },
      });
    }
  };
  /* istanbul ignore next */
  const defaultValue = (defaultVal) => {
    // console.log('defaultVal====', defaultVal);
    if (!allValues.length) {
      return '';
    }
    /* istanbul ignore next */
    const obj = mappedData.find((opt) => {
      const checkColumn = allValues.find((item) => {
        // console.log('defaultVal====', item);
        // console.log('defaultVal====1', opt.NEW_MAP_COL_NM);
        return item.value === opt.NEW_MAP_COL_NM;
      });

      return (
        opt.DEFINED_COL_NM == defaultVal &&
        checkColumn?.value === opt.NEW_MAP_COL_NM
      );
    });
    // console.log('obj=====', obj);
    if (obj !== undefined) {
      return obj.NEW_MAP_COL_NM;
    }
    return '';
  };

  React.useEffect(() => {
    // console.log('orignalValue=====', orignalValue);
    const defVal = defaultValue(orignalValue);
    // console.log('defVal=====', defVal);
    dispatch({
      type: 'SET_DEFAULT_OBJECT_ITEMS',
      value: { orignal: orignalValue, tobeUpdate: defVal },
    });
  }, [orignalValue]);

  return (
    <div>
      <FormControl
        className={classes}
        sx={{ minWidth: 240 }}
        style={{ matginTop: '13px !important' }}
        data-testid="formControl"
      >
        <Autocomplete
          data-testid="autocompleteList"
          autoSelect
          id={id}
          value={value}
          options={dropDownValues}
          getOptionLabel={(option) => {
            console.log('option======', value);
            return option?.value;
          }}
          defaultValue={{ value: defaultValue(orignalValue) }}
          sx={{
            width: 200,
            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
              fontSize: 13,
            },
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                required
                size="small"
                placeholder="Select option *"
                sx={{ width: 220, border: 'none' }}
                variant="outlined"
              />
            );
          }}
          onChange={(event, newInputValue) => {
            /* istanbul ignore next */
            handleChange(newInputValue?.value);
          }}
        />
      </FormControl>
    </div>
  );
};
