import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { Navbar } from '@src/components/Navbar';
import { Dashboard } from '@src/components/Dashboard';
import { Home } from './Home';
import { UpdateNewMappedColumns } from '../components/UpdateNewMappedColumns/UpdateMappedColumns';
import { LandingPage } from '../components/LandingPage/LandingPage';
import { RetireUI } from './RetireUI';
// import { msalConfig } from './authConfig';

// const msalInstance = new PublicClientApplication(msalConfig);

// if (
//   msalInstance.getActiveAccount() &&
//   msalInstance.getAllAccounts().length > 0
// ) {
//   msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
// }

// // Listen for Sign In and set Active Account
// msalInstance.addEventCallback((event) => {
//   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//     const { account } = event.payload;
//     msalInstance.setActiveAccount(account);
//   }
// });
export const Routes = () => {
  return (
    <Router.Routes title="USP Provider Compare">
      <Router.Route
        path="/UpdateNewMappedColumns"
        element={<RetireUI component="UpdateComponent" />}
      />
      <Router.Route
        path="/UpdateNewMappedColumnsNew"
        element={<Home component="UpdateComponent" />}
      />
      <Router.Route path="/home" element={<RetireUI component="Dashboard" />} />
      <Router.Route
        path="/"
        element={
          <Home component="Dashboard">
            <LandingPage />
          </Home>
        }
      />
    </Router.Routes>
  );
};
