import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { reducer } from './reducers/reducer';

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(logger);
  },
});

export default store;
