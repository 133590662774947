/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button } from '@abyss/web/ui/Button';
import { Divider } from '@abyss/web/ui/Divider';

import Grid from '@mui/material/Grid';
import { Label } from '@abyss/web/ui/Label';
import { useSelector } from 'react-redux';
import { Dropdown } from '../Dropdown';

/**
 * Used for processing mapping of component
 */

export const MapColumns = (props) => {
  const classes = {
    root: {
      flexGrow: 1,
      overflowX: 'hidden',
    },

    lebelStyle: {
      ml: 2,
      flex: 1,
      marginTop: '12px',
      color: '#1976d2',
      fontSize: '12px',
      fontWeight: 'bold',
      wordWrap: 'break-word',
    },
  };

  const { columns, mappedData, uploadedFileName } = props;
  const dropdown2 = 'dropdown-2';
  const selectOpExcel = 'Select option from excel';
  const [valFlag, setValFlag] = useState(false);
  /* istanbul ignore next */
  useEffect(() => {
    if (columns && columns.length && columns.length > 0) {
      setValFlag(true);
    }
  }, [columns]);
  const isDisableFlag = useSelector((state) => {
    return state?.isSubmitFlag;
  });

  return (
    <div style={classes.root}>
      <Divider />
      {/* <span
        style={{
          float: 'left',
          color: 'rgb(0, 38, 119)',
          marginLeft: '20px',
          fontWeight: '900',
          fontSize: '13px',
        }}
      >
      {/* Roster File Name : {uploadedFileName} */}
      {/* </span> */}
      <form
        onSubmit={(e) => {
          return props.handleFinalSubmit(e);
        }}
        style={{ marginTop: '5px', width: '98%' }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="PROVIDERTIN"
              >
                PROVIDERTIN
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="1"
                labelId="l-2"
                orignalValue="PROVIDERTIN"
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label style={classes.lebelStyle} size="$xl" data-testid="NPI">
                NPI
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="1"
                labelId="l-2"
                orignalValue="NPI"
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="NAMEFIRST"
              >
                NAMEFIRST
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="NAMEFIRST"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="NAMELAST"
              >
                NAMELAST
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="1"
                labelId="l-2"
                orignalValue="NAMELAST"
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="NAMEMIDDLE"
              >
                NAMEMIDDLE
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="1"
                labelId="l-2"
                orignalValue="NAMEMIDDLE"
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="NSUFFIX"
              >
                NSUFFIX
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="NSUFFIX"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="PROV_TAX_CODE"
              >
                PROV_TAX_CODE
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="PROV_TAX_CODE"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="ADDRESS1"
              >
                ADDRESS1
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="ADDRESS1"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="PLSV_PHONE"
              >
                PLSV_PHONE
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="PLSV_PHONE"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label style={classes.lebelStyle} size="$xl" data-testid="CITY">
                CITY
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="CITY"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label style={classes.lebelStyle} size="$xl" data-testid="STATE">
                STATE
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="STATE"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="POSTALCODE"
              >
                POSTALCODE
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="POSTALCODE"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="BILL_ADDRESS1"
              >
                BILL_ADDRESS1
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="BILL_ADDRESS1"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="BILL_CITY"
              >
                BILL_CITY
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="BILL_CITY"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="BILL_STATE"
              >
                BILL_STATE
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="BILL_STATE"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="BILL_POSTALCODE"
              >
                BILL_POSTALCODE
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="BILL_POSTALCODE"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="BILL_PHONE"
              >
                BILL_PHONE
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="BILL_PHONE"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="BILL_FAX_PHONE"
              >
                BILL_FAX_PHONE
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="BILL_FAX_PHONE"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label style={classes.lebelStyle} size="$xl" data-testid="GENDER">
                GENDER
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="GENDER"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="BIRTH_DATE"
              >
                BIRTH_DATE
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="BIRTH_DATE"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="DEA_NUMBER"
              >
                DEA_NUMBER
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="DEA_NUMBER"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="ST_LICENSE_NUM"
              >
                ST_LICENSE_NUM
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="ST_LICENSE_NUM"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="ST_LICENSE_HELD"
              >
                ST_LICENSE_HELD
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="ST_LICENSE_HELD"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="PRIM_DEG"
              >
                PRIM_DEG
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="PRIM_DEG"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container item row>
              <Label
                style={classes.lebelStyle}
                size="$xl"
                data-testid="SEC_DEG"
              >
                SEC_DEG
              </Label>
              <Dropdown
                allValues={columns}
                inputL={dropdown2}
                label={selectOpExcel}
                id="2"
                labelId="l-2"
                orignalValue="SEC_DEG"
                valFlag={valFlag}
                mappedData={mappedData}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: '5px' }} />
        <Grid
          container
          item
          alignContent="flex-start"
          spacing={2}
          style={{
            marginTop: '5px',
            marginLeft: '20px',
          }}
        >
          <Button
            type="submit"
            style={{
              padding: '0px 26px 0px 26px',
              fontSize: '12px',
            }}
            data-testid="saveButton"
            isDisabled={isDisableFlag}
          >
            Review
          </Button>
          <Button
            style={{
              padding: '8px 30px 8px 30px',
              marginLeft: '20px',
              fontSize: '12px',
            }}
            onClick={(e) => {
              return props.handleReset(e);
            }}
            variant="outline"
            data-testid="cancelButton"
            isDisabled={isDisableFlag}
          >
            Back
          </Button>
        </Grid>
      </form>
    </div>
  );
};
