/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';

export const MessagePopup = (props) => {
  const { message, open, errorName, errorCase, successCase } = props;
  let tittle = '';
  if (errorCase == 'err') {
    tittle = 'Error message';
  } else if (errorCase == 'auth') {
    tittle = 'information';
  }

  if (successCase) {
    tittle = 'Success message';
  }

  return (
    <React.Fragment>
      <Modal
        title={tittle}
        isOpen={open}
        onClose={(e) => {
          return props.handleClose(e);
        }}
      >
        <Modal.Section>
          <Grid>
            <Grid.Col span={{ xs: 12 }}>
              <span offset={3}>
                {errorCase == 'err' && (
                  <div
                    style={{ color: 'red', fontWeight: 'bold' }}
                    data-testid="columnOne"
                  >
                    {message}
                    <br />
                    Error Name: {errorName}
                  </div>
                )}

                {errorCase == 'auth' && (
                  <div
                    style={{ color: 'Green', fontWeight: 'bold' }}
                    data-testid="columnOne"
                  >
                    {message}
                    <br />
                    <a
                      href="https://uspprovidercompare.uhc.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://uspprovidercompare.uhc.com
                    </a>
                  </div>
                )}
                {successCase && (
                  <div
                    style={{ color: 'Green', fontWeight: 'bold' }}
                    data-testid="columnOne"
                  >
                    {message}
                  </div>
                )}
              </span>
            </Grid.Col>
          </Grid>
        </Modal.Section>
        <Modal.Section>
          <Layout.Group alignLayout="right">
            <Button
              onClick={(e) => {
                return props.handleAcknowledge(e);
              }}
            >
              OK
            </Button>
          </Layout.Group>
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );
};
