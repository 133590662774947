import { Divider } from '@abyss/web/ui/Divider';
import Grid from '@mui/material/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import React from 'react';

export const NoDataComponent = () => {
  return (
    <React.Fragment>
      <div style={{ margin: '20px' }}>
        <Divider />
        <Grid
          container
          direction="rows"
          spacing={1}
          item
          xs={12}
          style={{
            marginTop: '16px',
            padding: '10px',
            paddingBottom: '210px',
          }}
        >
          <Grid item xs={12}>
            <Heading data-testid="hidelabel">
              Please Upload a file, Map column UI open when file uploaded
              successfully
            </Heading>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
