/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import Grid from '@mui/material/Grid';
import { Label } from '@abyss/web/ui/Label';

export const TextField = (props) => {
  const { sheetColumns, setRosterNameError, activeAccountEmail, nameError } =
    props;
  const [value, setValue] = useState('');
  /* istanbul ignore next */
  const checkDisable = !(sheetColumns && sheetColumns.length > 0);
  const handleRosterNameChange = (value) => {
    setValue(value);
    props.setRosterName(value);
    localStorage.setItem('rosterName', value);
    setRosterNameError(false);
  };

  const classes = {
    root: {
      flexGrow: 1,
      margin: '5px 10px 10px 40px',
    },
    lebelStyle: {
      display: 'inline-block',
      marginTop: '15px',
      fontWeight: 'bold',
      float: 'left',
      fontSize: '13px',
    },
  };

  return (
    <div style={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={3} sm={12}>
          <Grid container item row>
            <Label size="$xl" data-testid="label" style={classes.lebelStyle}>
              Please Enter Roster Name *
            </Label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <TextInput
              isDisabled={!checkDisable}
              data-testid="TextInputId"
              value={
                localStorage.getItem('rosterName')
                  ? localStorage.getItem('rosterName')
                  : ''
              }
              width="50%"
              onChange={(e) => {
                return handleRosterNameChange(e.target.value);
              }}
              css={{
                'abyss-form-input-wrapper-container': {
                  border: nameError ? '2px solid red !important' : 'none',
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={3} sm={12}>
          <Grid container item row>
            <Label
              size="$xl"
              style={classes.lebelStyle}
              data-testid="Email-label"
            >
              Submitter Email *
            </Label>
            <TextInput
              width="50%"
              data-testid="TextInputEmail"
              isDisabled
              id="2"
              value={activeAccountEmail}
              // onChange={(e) => {
              //   handleRosterEmailChange(e.target.value);
              // }}
              disabled
              css={{
                'abyss-text-input-root': { marginLeft: '75px' },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
