/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';

export const ReviewPopup = (props) => {
  const dispatch = useDispatch();
  const mappedColumnValues = useSelector((state) => {
    return state?.objectArray;
  });
  const [isData, setIsData] = useState(mappedColumnValues || []);
  let defaultDisplayList = useSelector((state) => {
    return state?.mappedColumnArray;
  });
  /* istanbul ignore next */
  defaultDisplayList = uniqByKeepLast(defaultDisplayList, (item) => {
    return item.orignal;
  });
  const backButtonClick = useSelector((state) => {
    return state?.backButtonStatus;
  });
  /* istanbul ignore next */
  function uniqByKeepLast(a, key) {
    return [
      ...new Map(
        a.map((x) => {
          return [key(x), x];
        })
      ).values(),
    ];
  }

  useEffect(() => {
    if (backButtonClick) {
      setIsData([]);
    }
  }, [mappedColumnValues]);

  const commonList = defaultDisplayList.concat(isData);
  const reviewedColumnValuesList = uniqByKeepLast(commonList, (item) => {
    return item.orignal;
  });

  useEffect(() => {
    dispatch({
      type: 'SET_REVIEWED_ROW_VALUES',
      value: reviewedColumnValuesList,
    });
  }, reviewedColumnValuesList);

  return (
    <React.Fragment>
      <Modal
        title="Mapped column Details"
        isOpen={props.open}
        onClose={(e) => {
          return props.handleClose(e);
        }}
        data-testid="reviewPopup"
      >
        <Modal.Section>
          <Grid style={{ marginLeft: '15px' }}>
            <Grid.Col span={{ xs: 12, md: 6 }}>
              <span offset={3}>
                <div
                  style={{ color: 'blue', fontWeight: 'bold' }}
                  data-testid="columnOne"
                >
                  Mapped Column Name
                </div>
              </span>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, md: 6 }}>
              <span offset={3}>
                <div
                  style={{ color: 'blue', fontWeight: 'bold' }}
                  data-testid="columnTwo"
                >
                  Sheet's Column Name
                </div>
              </span>
            </Grid.Col>
            {reviewedColumnValuesList.map((item) => {
              return (
                <React.Fragment>
                  <Grid.Col span={{ xs: 12, md: 6 }}>
                    <span offset={3}>
                      <div data-testid="orignalValue">{item.orignal}</div>
                    </span>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 12, md: 6 }}>
                    <span offset={3}>
                      <div data-testid="updatedValue">{item.tobeUpdate}</div>
                    </span>
                  </Grid.Col>
                </React.Fragment>
              );
            })}
          </Grid>
        </Modal.Section>
        <Modal.Section>
          <Layout.Group alignLayout="right">
            <Button
              variant="outline"
              onClick={(e) => {
                return props.handleClose(e);
              }}
              data-testid="cancelButton"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                return props.handleSubmit(e);
              }}
              data-testid="submitButton"
            >
              Submit
            </Button>
          </Layout.Group>
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );
};
