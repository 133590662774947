import {
  HIDE_DIALOG,
  SHOW_DIALOG,
  START_LOADER,
  STOP_LOADER,
} from './actionTypes';

export const startLoader = () => {
  return (dispatch) => {
    dispatch({ type: 'START_LOADER', data: true });
  };
};
export const stopLoader = () => {
  return (dispatch) => {
    dispatch({ type: 'STOP_LOADER', data: false });
  };
};
export const showDialog = () => {
  return (dispatch) => {
    dispatch({ type: 'SHOW_DIALOG', data: true });
  };
};
export const hideDialog = () => {
  return (dispatch) => {
    dispatch({ type: 'HIDE_DIALOG', data: false });
  };
};
