import React from 'react';
import { PageFooter } from '@abyss/web/ui/PageFooter';

export const Footer = () => {
  return (
    <PageFooter
      css={{
        'abyss-page-footer-root': {
          background: '#002677',
          color: 'white',
          margin: '20px 0px 0px 0px',
        },
        'abyss-page-footer-sub-wrapper': {
          color: '#fff',
        },
      }}
      subFooterTitle="United HealthCare Services, Inc"
    >
      <PageFooter.Section
        css={{
          'abyss-page-footer-section-title': {
            color: 'white',
          },
        }}
      >
        <PageFooter.Link
          css={{
            'abyss-page-footer-link': {
              color: '#fff',
            },
          }}
          href="#"
        >
          About Us
        </PageFooter.Link>
      </PageFooter.Section>
      <PageFooter.Section
        css={{
          'abyss-page-footer-section-title': {
            color: 'white',
          },
        }}
      >
        <PageFooter.Link
          css={{
            'abyss-page-footer-link': {
              color: '#fff',
            },
          }}
          href="#"
        >
          Contact Us
        </PageFooter.Link>
      </PageFooter.Section>
    </PageFooter>
  );
};
