import { START_LOADER, STOP_LOADER } from '../actionTypes';

const initialState = {
  loading: false,
  dialog: false,
  objectArray: [],
  setClear: false,
  mappedColumnArray: [],
  isFileUploaded: false,
  selectedRowCount: 0,
  backButtonStatus: false,
  isSubmitFlag: false,
  reviewedList: [],
  fetchMappedList: [],
  conDetailFlag: 'NO',
};
function uniqByKeepLast(a, key) {
  return [
    ...new Map(
      a.map((x) => {
        return [key(x), x];
      })
    ).values(),
  ];
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_ACCOUNT_EMAIL':
      return {
        ...state,
        activeAccountEmail: action.value,
      };
    case 'SET_ACTIVE_ACCOUNT_USER_NAME':
      return {
        ...state,
        activeAccountUserName: action.value,
      };
    case START_LOADER:
      return {
        ...state,
        loading: action.data,
      };

    case STOP_LOADER:
      return {
        ...state,
        loading: action.data,
      };
    case 'FILE_UPLOAD_STATUS':
      return {
        ...state,
        isFileUploaded: action.value,
        // mappedColumnArray: [],
      };

    case 'SET_OBJECT_VALUES':
      let array = state?.objectArray;
      array = uniqByKeepLast(array, (item) => {
        return item.orignal;
      });

      // if (action.value.tobeUpdate !== 'None') {
      array.push(action.value);
      // }
      array = uniqByKeepLast(array, (item) => {
        return item.orignal;
      });

      return {
        ...state,
        objectArray: array,
      };

    case 'SET_CLEAR':
      return {
        ...state,
        objectArray: [],
      };
    case 'SET_DEFAULT_OBJECT_ITEMS':
      let defaultListPopup = state?.mappedColumnArray;
      defaultListPopup.push(action.value);
      defaultListPopup = [...new Set(defaultListPopup)];

      return {
        ...state,
        mappedColumnArray: defaultListPopup,
      };

    case 'SET_SELECTED_ROW__VALUES':
      return {
        ...state,
        selectedRowCount: action.value,
      };

    case 'FILE_BACK_BUTTON_STATUS':
      return { ...state, backButtonStatus: action.value };
    case 'SUBMIT_BUTTON_CLICKED':
      return {
        ...state,
        isSubmitFlag: action.value,
      };
    case 'SET_REVIEWED_ROW_VALUES':
      return {
        ...state,
        reviewedList: action.value,
      };
    case 'FETCHED_SELECTED_ROW__VALUES':
      // console.log('action value====', action.value);
      return { ...state, fetchMappedList: action.value };

    case 'SET_CON_DETAILS_FLAG':
      return {
        ...state,
        conDetailFlag: action.value,
      };
    default:
      return state;
  }
};
