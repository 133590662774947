/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Snackbar, Alert } from '@mui/material';
import { Label } from '@abyss/web/ui/Label';
import { Button } from '@abyss/web/ui/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { read, utils, write } from 'xlsx';
import { FileUpload } from '@abyss/web/ui/FileUpload';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { startLoader, showDialog, stopLoader } from '../../store/actions';
import { Loader } from '../Loader';
import { MapColumns } from '../MapColumns';
import { TextField } from '../TextField';
import { hostServer, options } from '../../utils/constants';
import { ReviewPopup } from '../ReviewPopup';
import { MessagePopup } from '../MessagePopup';
// import UpdateMappedColumn from '../UpdateNewMappedColumns/updateMappedColumns';
import { NoDataComponent } from '../NoDataComponent';
import { UploadedFilesHistory } from '../UploadedFilesHistory';
// import ContactDetailsComponent from './ContactDetailsComponents';

const BILLINGZIP = 'BILL_POSTALCODE';
const PLSVZIP = 'POSTALCODE';
const serverUrl = hostServer.dev;

export const Dashboard = () => {
  const navigate = useNavigate();
  const classes = {
    root: {
      flexGrow: 1,
    },

    lebelStyle: {
      display: 'inline-block',
      marginTop: '15px',
      fontWeight: 'bold',
      float: 'left',
      fontSize: '13px',
      margin: '14px 1px 0px 40px',
    },
  };
  const loading = useSelector((state) => {
    return state.loading;
  });
  // const dialog = useSelector((state) => {
  //   return state.dialog;
  // });
  const objectArrayVal = useSelector((state) => {
    return state;
  });

  const activeAccountEmail = useSelector((state) => {
    return state.activeAccountEmail;
  });
  const selectedRowCounts = useSelector((state) => {
    return state?.selectedRowCount;
  });

  const indicatorVal = useSelector((state) => {
    return state?.conDetailFlag;
  });

  const [sheetColumns, setSheetColumns] = useState([]);
  const [sheetJsonData, setSheetJsonData] = useState({});
  const [showError, setShowError] = useState(false);
  const [showToster, setShowToster] = useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [deafultColumnList, setDeafultColumnList] = useState([]);
  const [mappedData, setMappedData] = useState([]);
  const [error, setError] = useState(false);
  let [rosterName, setRosterName] = useState('');
  const [nameError, setRosterNameError] = useState(false);
  const [successMessage, setDropDownValidation] = useState(false);
  const [errorMessage, setDataErrorMessage] = useState(false);
  const [selectedVal, setSelectedVal] = useState(selectedRowCounts || '1');
  const checkDisable = !(sheetJsonData && sheetJsonData.length > 0);
  const [emptyFileError, setEmptyFileError] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [headerSelectError, setHeaderSelectError] = useState(false);
  const [errorNameMessage, setErrorNameMsg] = useState('');
  const [tinValidation, setTinValidation] = useState(false);
  const [npiValidation, setNPIValidation] = useState(false);
  const [authFlag, setAuthFlag] = useState(false);

  if (selectedVal === '') {
    localStorage.removeItem('Name');
  }

  const dispatch = useDispatch();
  const selectRowValues = (selectedValue) => {
    localStorage.setItem('Name', selectedValue);
    setSelectedVal(selectedValue);

    dispatch({
      type: 'SET_SELECTED_ROW__VALUES',
      value: selectedValue,
    });
  };
  /* istanbul ignore next */
  useEffect(() => {
    if (selectedVal || selectedRowCounts) {
      setSelectedVal(selectedVal || selectedRowCounts);
    }
  }, [selectedVal, selectedRowCounts]);

  /* istanbul ignore next */
  const fetchcolumnsData = () => {
    axios
      .get(`${serverUrl}/fetchMappedcolumn`)
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData.length && responseData.length > 0) {
          setMappedData(response.data.results);
          dispatch({
            type: 'FETCHED_SELECTED_ROW__VALUES',
            value: response.data.results,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  /* istanbul ignore next */
  useEffect(() => {
    fetchcolumnsData();
    localStorage.setItem('Name', 1);
    setSelectedVal(1);
    localStorage.removeItem('rosterName');
    // setAuthFlag(true);
  }, []);
  /* istanbul ignore next */
  const savedMappedColums = (objectData) => {
    axios
      .post(`${serverUrl}/saveMappedColumn`, { data: objectData })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* istanbul ignore next */
  const insertMappedData = (MappedData) => {
    MappedData.forEach((item) => {
      if (
        typeof item.PROVIDERTIN === 'number' &&
        item.PROVIDERTIN.toString().length !== 9
      ) {
        item.PROVIDERTIN = item.PROVIDERTIN.toString().padStart(9, '0');
      }
    });

    axios
      .post(`${serverUrl}/saveColumnData`, { data: MappedData })
      .then((response) => {
        if (response?.data?.err) {
          fetchcolumnsData();
          setDataErrorMessage(true);
          setErrorNameMsg(response?.data?.err?.message);
        }
        if (response?.data?.results) {
          fetchcolumnsData();
          setDropDownValidation(true);
        }
      })
      .catch((err) => {});
  };
  /* istanbul ignore next */
  useEffect(() => {
    if (sheetColumns.length) {
      dispatch(showDialog());
      dispatch({ type: 'FILE_UPLOAD_STATUS', value: true });
      dispatch({ type: 'SUBMIT_BUTTON_CLICKED', value: false });
    }
    // eslint-disable-next-line
  }, [sheetColumns]);

  /* istanbul ignore next */
  const fileTypesAccepted = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
      '.csv',
    ],
  };

  function ec(r, c) {
    return utils.encode_cell({ r, c });
  }
  /* istanbul ignore next */
  function deleteRow(ws, row_index) {
    const variable = utils.decode_range(ws['!ref']);
    for (let R = row_index; R < variable.e.r; ++R) {
      for (let C = variable.s.c; C <= variable.e.c; ++C) {
        ws[ec(R, C)] = ws[ec(R + 1, C)];
      }
    }
    variable.e.r--;
    ws['!ref'] = utils.encode_range(variable.s, variable.e);
  }

  /* istanbul ignore next */
  const onFileChangeHandler = (e) => {
    const selectedHeader = localStorage.getItem('Name');
    if (selectedHeader === null) {
      setTimeout(() => {
        setHeaderSelectError(true);
      }, 500);
      return;
    }

    if (e == 0 || e == []) {
      setShowError(true);
    }
    const ext = e[0]?.name.split('.').pop();
    if (e) {
      if (ext !== 'csv' && ext !== 'xlsx' && ext !== 'xlx') {
        setShowError(true);
      } else {
        setShowError(false);
        setShowToster(true);
        setLoading(true);
        dispatch(startLoader());
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;

          const workbook = read(data, { type: 'array' });
          // SetMainWorkBook(workbook);
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const lastColumnIndex = localStorage.getItem('Name');
          for (
            let selectIndex = 1;
            selectIndex < lastColumnIndex;
            selectIndex++
          ) {
            deleteRow(worksheet, 0);
          }

          const json = utils.sheet_to_json(worksheet, {
            raw: true,
            defval: null,
            blankrows: false,
          });
          setSheetJsonData(json);
          if (json && json.length === 0) {
            setTimeout(() => {
              setShowToster(false);
              setEmptyFileError(true);
              dispatch(stopLoader());
              setLoading(false);
            }, 5000);
            return;
          }

          const allKeys = Object.keys(json[0]);
          const cols = allKeys.map((item) => {
            return { value: item };
          });

          cols.sort((a, b) => {
            if (a.value < b.value) {
              return -1;
            }
            if (a.value > b.value) {
              return 1;
            }
            return 0;
          });

          setSheetColumns(cols);
          dispatch(stopLoader());
          setLoading(false);
        };
        reader.readAsArrayBuffer(e[0]);
      }
    }
  };
  /* istanbul ignore next */
  const handleErrorClose = () => {
    setShowError(false);
    setShowToster(false);
    setDropDownValidation(false);
    setRosterNameError(false);
    setEmptyFileError(false);
    // setDataErrorMessage(false);
    setHeaderSelectError(false);
  };
  /* istanbul ignore next */
  const handleReset = () => {
    setSheetColumns([]);
    dispatch({ type: 'FILE_UPLOAD_STATUS', value: false });
    setRosterName('');
    dispatch({ type: 'FILE_BACK_BUTTON_STATUS', value: true });
    localStorage.removeItem('rosterName');
    dispatch({
      type: 'SET_REVIEWED_ROW_VALUES',
      value: [],
    });
  };
  /* istanbul ignore next */
  const onReviewPopupClick = (e) => {
    e.preventDefault();
    if (!rosterName || rosterName === '') {
      setRosterNameError(true);
      return;
    }
    setIsReview(true);
  };

  const handleClose = () => {
    /* istanbul ignore next */
    setIsReview(false);
  };
  const handleCloseMSG = () => {
    /* istanbul ignore next */
    setDataErrorMessage(false);
    window.location.reload();
  };
  const handleClosePopup = () => {
    /* istanbul ignore next */
    setNPIValidation(false);
    setTinValidation(false);
    setAuthFlag(false);
    // window.location.reload();
  };

  const backButtonClick = useSelector((state) => {
    return state?.backButtonStatus;
  });
  /* istanbul ignore next */
  const listToSaveDatabase = (json) => {
    const finalListOfData = [];
    const finalMapping = objectArrayVal?.reviewedList;
    for (let j = 0; j < json.length; j++) {
      const newRow = {};

      for (let i = 0; i < finalMapping?.length; i++) {
        const definedColumnName = finalMapping[i].orignal; // defined column name
        const mappedColumnFromSheet = finalMapping[i].tobeUpdate; // sheet column name
        newRow[definedColumnName] = json[j][mappedColumnFromSheet];
      }
      finalListOfData.push(newRow);
    }

    return finalListOfData;
  };

  const validationFunction = (finalData) => {
    let npiFlag = false;
    const regexForNpi = /^\d{10}$/;
    const findWrongNpi = finalData.find((o) => {
      return !regexForNpi.test(o.NPI);
    });
    if (findWrongNpi && Object.keys(findWrongNpi).length !== 0) {
      npiFlag = true;
    }
    if (npiFlag) {
      setNPIValidation(true);
    }
    return npiFlag;
  };
  const providerTinValidation = (finalData) => {
    let tinFlag = false;
    const regexForTin = /^\d+$/; // /^\d{9}$/;

    const findWrongTin = finalData.find((o) => {
      return !regexForTin.test(o.PROVIDERTIN);
    });
    if (findWrongTin && Object.keys(findWrongTin).length !== 0) {
      tinFlag = true;
    }
    if (tinFlag) {
      setTinValidation(true);
    }
    return tinFlag;
  };

  /* istanbul ignore next */
  const onMappingSubmit = () => {
    let selectedColumnList = objectArrayVal?.objectArray;
    if (backButtonClick) {
      selectedColumnList.length = 0;
    }

    selectedColumnList = [...selectedColumnList].reverse();
    let json = sheetJsonData;
    const obj = selectedColumnList;
    const payload = readDataFromExcel(obj, deafultColumnList);

    const newFinalMappedData = listToSaveDatabase(json);
    const finalJson = [];
    json = newFinalMappedData;
    sortedJsonData(json);
    for (let i = 0; i < json.length; i++) {
      finalJson.push(sortObject(json[i]));
    }

    const validationFlag = validationFunction(finalJson);
    const tinValidationCheck = providerTinValidation(finalJson);

    if (validationFlag || tinValidationCheck) return;
    savedMappedColums(payload);
    insertMappedData(finalJson);

    const newFile = utils.json_to_sheet(finalJson);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, newFile, 'myWorkSheet');
    const fileName = sheetFileName();
    const generatedFile = write(wb, {
      bookType: 'csv',
      bookSST: true,
      type: 'array',
    });
    const data = new Blob([generatedFile], {
      type: 'application/vnd.ms-excel',
    });

    data.name = `${fileName}.csv`;

    setLoading(true);
    dispatch(startLoader());
    dispatch({ type: 'SUBMIT_BUTTON_CLICKED', value: true });

    setTimeout(() => {
      setSheetColumns([]);
      dispatch(stopLoader());
      setLoading(false);
      setRosterName('');

      dispatch({
        type: 'SET_REVIEWED_ROW_VALUES',
        value: [],
      });
      dispatch({ type: 'FILE_UPLOAD_STATUS', value: false });
      localStorage.setItem('Name', 1);
      setSelectedVal(1);
      localStorage.removeItem('rosterName');
    }, 8000);
    dispatch({
      type: 'SET_REVIEWED_ROW_VALUES',
      value: [],
    });
  };
  const handleAcknowledge = (event) => {
    event.preventDefault();
    setDataErrorMessage(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onMappingSubmit();
    setIsReview(false);
  };
  /* istanbul ignore next */
  const sortedJsonData = (jsonData) => {
    const rosterFile = sheetFileName();
    jsonData.map((item) => {
      item.EMAIL = activeAccountEmail;
      item.ROSTERFILENAME = rosterFile;
      // item.CON_DETAIL_IND = indicatorVal;

      if (item.NPI) {
        item.NPI = parseInt(item.NPI);
      }
      if (item.PROVIDERTIN && /[,\-]/.test(item.PROVIDERTIN)) {
        const removeDashes = item.PROVIDERTIN.replace(/-/g, '');
        item.PROVIDERTIN = removeDashes;
      }
      if (item[PLSVZIP]) {
        item[PLSVZIP] = parseInt(item[PLSVZIP]);
      }
      if (item[BILLINGZIP]) {
        item[BILLINGZIP] = parseInt(item[BILLINGZIP]);
      }
    });

    return jsonData;
  };

  /* istanbul ignore next */
  function sortObject(obj) {
    return Object.keys(obj)
      .sort((a, b) => {
        const aIsUpperCase = a.toUpperCase() === a;
        const bIsUpperCase = b.toUpperCase() === b;
        if (aIsUpperCase === bIsUpperCase) return a.localeCompare(b, 'es');
        if (aIsUpperCase) return -1;
        return 1;
      })
      .reduce((result, key) => {
        result[key] = obj[key];
        return result;
      }, {});
  }
  /* istanbul ignore next */
  const sheetFileName = () => {
    const d = new Date();
    const formatedData = `${
      d.getMonth() + 1
    }-${d.getDate()}-${d.getFullYear()}_${d.getHours()}_${d.getMinutes()}_${d.getSeconds()}`;
    if (rosterName) {
      rosterName = rosterName.trim();
      rosterName = rosterName.replace(/ /g, '_');
      return `${rosterName}${'_'}${formatedData}`;
    }
  };
  /* istanbul ignore next */
  const findDefaultColumnList = () => {
    const defaultList = mappedData;
    const orgSheetColumns = sheetColumns;
    const finalDefColumnList = [];
    for (let j = 0; j < orgSheetColumns.length; j++) {
      for (let i = 0; i < defaultList.length; i++) {
        if (orgSheetColumns[j].value === defaultList[i].NEW_MAP_COL_NM) {
          finalDefColumnList.push({
            orignal: defaultList[i].DEFINED_COL_NM,
            tobeUpdate: defaultList[i].NEW_MAP_COL_NM,
          });
        }
      }
    }

    setDeafultColumnList(finalDefColumnList);
  };
  /* istanbul ignore next */
  useEffect(() => {
    if (sheetColumns && sheetColumns.length && sheetColumns.length > 0) {
      findDefaultColumnList();
    }
  }, [sheetColumns]);

  /* istanbul ignore next */
  const readDataFromExcel = (selectedList, predefinedList) => {
    predefinedList = JSON.stringify(predefinedList);
    let payload = [];
    for (let j = 0; j < selectedList.length; j++) {
      if (!predefinedList.includes(JSON.stringify(selectedList[j]))) {
        payload.push(selectedList[j]);
      }
    }

    payload = payload.filter((v, i, a) => {
      return (
        a.findIndex((v2) => {
          return v2.orignal === v.orignal;
        }) === i
      );
    });
    return payload;
  };

  const updateMappedColumn = () => {
    navigate('/UpdateNewMappedColumns');
    // setUpdateFlg(true);
  };

  return (
    <React.Fragment>
      <Backdrop data-testid="loading-1" open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>

      <React.Fragment>
        {isReview && (
          <ReviewPopup
            open={isReview}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        )}
        {errorMessage && (
          <MessagePopup
            open={errorMessage}
            handleClose={handleCloseMSG}
            handleAcknowledge={handleAcknowledge}
            message="Submission failed.  Please submit screenshot of error to R&T."
            errorName={errorNameMessage}
            errorCase="err"
          />
        )}

        {successMessage && (
          <MessagePopup
            open={successMessage}
            handleClose={handleCloseMSG}
            handleAcknowledge={handleAcknowledge}
            message="Submission Saved Successfully."
            errorName=""
            successCase
          />
        )}
        {npiValidation && (
          <MessagePopup
            open={npiValidation}
            handleClose={handleClosePopup}
            handleAcknowledge={handleClosePopup}
            message="File Not Processed."
            errorName="NPI should be 10 digits numeric only. Please correct your file data."
            errorCase="err"
          />
        )}
        {tinValidation && (
          <MessagePopup
            open={tinValidation}
            handleClose={handleClosePopup}
            handleAcknowledge={handleClosePopup}
            message="File Not Processed."
            errorName="TIN should be Numeric only. Please correct your file data."
            errorCase="err"
          />
        )}

        {authFlag && (
          <MessagePopup
            open={authFlag}
            handleClose={handleClosePopup}
            handleAcknowledge={handleClosePopup}
            message="This is the DEV/UAT environment, if you are not testing new functionality please use the following production URL."
            // errorName="TIN should be 9 digits numeric only."
            errorCase="auth"
          />
        )}

        <Snackbar
          open={showError}
          autoHideDuration={6000}
          onClose={handleErrorClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="error">
            <strong>Only excel Files Accepted!</strong>
          </Alert>
        </Snackbar>
        {!emptyFileError && showToster && (
          <Snackbar
            open={showToster}
            autoHideDuration={6000}
            onClose={handleErrorClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert severity="success">
              <strong>Files uploaded Successfully!</strong>
            </Alert>
          </Snackbar>
        )}

        <Snackbar
          open={nameError}
          autoHideDuration={6000}
          onClose={handleErrorClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="error">
            <strong>Please Enter Roster Name!</strong>
          </Alert>
        </Snackbar>
        {emptyFileError && !showToster && (
          <Snackbar
            open={emptyFileError}
            autoHideDuration={6000}
            onClose={handleErrorClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert severity="error">
              <strong>Your File is Empty, Please Upload diffrent file!</strong>
            </Alert>
          </Snackbar>
        )}
        {headerSelectError && !showToster && !emptyFileError && (
          <Snackbar
            open={headerSelectError}
            autoHideDuration={6000}
            onClose={handleErrorClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert severity="error">
              <strong> Please Select Header Row!</strong>
            </Alert>
          </Snackbar>
        )}
        <Box>
          {/* <MapperDialog columns={sheetColumns} open={showMapperDialog} /> */}
          <Loader open={loading} />
          <Box className="home">
            {!isLoading && (
              <React.Fragment>
                <div style={classes.root}>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      background: 'cadetblue',
                      border: '2px solid white',
                    }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Grid container item row style={{ marginTop: '10px' }}>
                        <Label
                          size="$xl"
                          style={classes.lebelStyle}
                          data-testid="selectHeader"
                        >
                          Please Select Header Row *
                        </Label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <SelectInput
                          placeholder="Please select"
                          data-testid="selectInput"
                          isSearchable
                          value={
                            localStorage.getItem('Name')?.length > 0
                              ? selectedVal
                              : 1
                          }
                          css={{
                            'abyss-select-input-input-container': {
                              border: headerSelectError
                                ? '2px solid red !important'
                                : 'none',
                            },
                          }}
                          isDisabled={!checkDisable}
                          width="auto"
                          onChange={(e) => {
                            /* istanbul ignore next */
                            return selectRowValues(e);
                          }}
                          options={options}
                        />
                      </Grid>
                      <TextField
                        setRosterName={setRosterName}
                        // setRosterEmail={setRosterEmail}
                        sheetColumns={sheetColumns}
                        error={error}
                        setError={setError}
                        setRosterNameError={setRosterNameError}
                        nameError={nameError}
                        activeAccountEmail={activeAccountEmail}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3} style={{ marginTop: '8px' }}>
                      {/* <ContactDetailsComponent /> */}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FileUpload
                        maxMessage={false}
                        maxFiles={1}
                        label="FileUpload Sandbox"
                        isDisabled={!checkDisable || rosterName == ''}
                        hideLabel
                        fileTypes={fileTypesAccepted || ''}
                        onChange={(e) => {
                          return onFileChangeHandler(e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1} style={{ marginTop: '5px' }}>
                      <UploadedFilesHistory />
                      <Button
                        variant="tertiary"
                        onClick={() => {
                          updateMappedColumn();
                        }}
                        className="updateColumn"
                        css={{
                          'abyss-button-root': {
                            color: 'white',
                          },
                        }}
                      >
                        Update Columns
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </React.Fragment>
            )}
            {sheetColumns && sheetColumns.length && sheetColumns.length > 0 ? (
              <MapColumns
                columns={sheetColumns}
                handleFinalSubmit={(e) => {
                  return onReviewPopupClick(e);
                }}
                handleReset={handleReset}
                mappedData={mappedData}
              />
            ) : (
              <NoDataComponent />
            )}
          </Box>
        </Box>
      </React.Fragment>
    </React.Fragment>
  );
};
