import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button } from '@abyss/web/ui/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { hostServer } from '../../utils/constants';

const serverUrl = hostServer.dev;

export const UploadedFilesHistory = () => {
  const [FileHistoryData, setFileHistoyData] = useState([]);
  const activeAccountEmail = useSelector((state) => {
    return state.activeAccountEmail;
  });

  const fetchUploadedFile = (email) => {
    axios
      .get(`${serverUrl}/fetchUploadedFile?emailId=${email}`)
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData.length && responseData.length > 0) {
          const formatedList = [];
          responseData.forEach((item) => {
            formatedList.push({
              rosterFileName: item.ROSTERFILENAME,
              updatedDate: item?.TIMESTAMPS.substring(0, '10'),
              updatedTime: item?.TIMESTAMPS.substring(11, '19'),
            });
          });
          // console.log('formatedList', formatedList);
          setFileHistoyData(formatedList); //
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    fetchUploadedFile(activeAccountEmail);
  }, [activeAccountEmail]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {FileHistoryData && FileHistoryData?.length > 0 ? (
        <React.Fragment>
          <Button
            variant="tertiary"
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onMouseEnter={(e) => {
              handleMenuClick(e);
            }}
            css={{
              'abyss-button-root': {
                color: 'white',
              },
            }}
          >
            History
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {FileHistoryData.map((val, key) => {
              return (
                <MenuItem
                  className="showColumn"
                  // style={{ fontSize: 'smaller' }}
                  // onClick={handleClose}
                >
                  {val.rosterFileName}
                </MenuItem>
              );
            })}
          </Menu>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};
